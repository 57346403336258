@tailwind base;
@tailwind components;
@tailwind utilities;

.table-auto th,
.table-auto td {
    vertical-align: middle; /* Ensures vertical alignment */
    text-align: center;     /* Centers text horizontally */
}

@media only screen and (max-width: 768px) {
    .menu-1 {
        display: block;
    }
}

@media only screen and (max-width: 1130px) {
    .marginTop8px {
        margin-top: 8px;
    }
}

@media only screen and (max-width: 768px) {
    .displayNone {
        display: none;
    }
}
@media only screen and (max-width: 600px) {
    .menu-2 {
        display: block;
        /* font-size: 11px; */
    }
}
@media only screen and (max-width: 500px) {
    body {
        font-size:12px;
    }
}
.star-item {
    display: inline-block;
    margin-bottom: 6px;
}

.truncate {
    -webkit-line-clamp: 3;
    /* Adjust the number of lines */
    max-width: calc(12 * 1em);
    /* Adjust the number of words */
    text-overflow: ellipsis;
}

.slick-dots li button:before {
    font-family: none !important;
}

.slick-prev:before,
.slick-next:before {
    font-size: 50px !important;
}

.ant-carousel .slick-next:after {
    display: none !important;
}

.ant-carousel .slick-prev:after {
    display: none !important;
}

input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 5px;
}

input[type="range"]::-moz-range-track {
    -moz-appearance: none;
    height: 5px;
}

input[type="range"]::-ms-track {
    appearance: none;
    height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 2rem;
    height: 2rem;
    background-color: white;
    border: solid 1px #ccc;
    border-radius: 1rem;
    margin-top: -0.6rem;
    pointer-events: auto;
    cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb:active {
    border: solid 1px rgb(144, 211, 255);
}

input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    width: 2rem;
    height: 2rem;
    background-color: white;
    border: solid 1px #ccc;
    border-radius: 1rem;
    margin-top: -0.6rem;
    pointer-events: auto;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb:active {
    border: solid 1px rgb(144, 211, 255);
}

input[type="range"]::-ms-thumb {
    appearance: none;
    width: 2rem;
    height: 2rem;
    background-color: white;
    border: solid 1px #ccc;
    border-radius: 1rem;
    margin-top: -0.6rem;
    pointer-events: auto;
    cursor: pointer;
}

input[type="range"]::-ms-thumb:active {
    border: solid 1px rgb(144, 211, 255);
}

button.slick-arrow::before {
    font-size: 50px !important;
    color: yellow !important;
}

.slick-next {
    right: 35px !important;
    top: 45% !important;
}

.slick-prev {
    left: 10px !important;
    z-index: 2 !important;
    top: 45% !important;

}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
}